import Config from './config.json';

type EnvVars = keyof Pick<
  ImportMetaEnv,
  | 'VITE_ENV'
  | 'VITE_AUTH_AUDIENCE'
  | 'VITE_AUTH_CLIENT_ID'
  | 'VITE_AUTH_AUTHORITY'
  | 'VITE_AUTH_GRAPH_API_ME'
  | 'VITE_SERVICE_GRAPHQL'
  | 'VITE_SERVICE_ANON_GRAPHQL'
  | 'VITE_MIXPANEL_ANALYTICS_TOKEN'
  | 'VITE_SENTRY_DSN'
  | 'VITE_FLAGSMITH_ENV_KEY'
  | 'VITE_APP_LINK_LOGIN'
  | 'VITE_SMART_APP_BANNER_ANDROID_LINK'
  | 'VITE_SERVICE_DOCS'
>;

// Necessary to inject environment variables using start-nginx.sh
// at runtime on production build
export const getEnvVariable = (variable: EnvVars) =>
  Config[variable] && !Config[variable].includes('$VITE_')
    ? Config[variable]
    : import.meta.env[variable];

export const isLive = () => getEnvVariable('VITE_ENV') === 'live';
