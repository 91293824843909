import {
  Body,
  colors,
  fontSizes,
  Grid,
  Spacer,
  Text,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage } from 'react-intl';

import { Button, Footer, TasksContainer } from './styles';
import {
  useHypertensionHomeBannerStatus,
  useTodayPatientObservations,
} from './hooks';
import { WardPatientObservation } from '../../services/graphql';
import { ErrorNotification } from './ErrorNotification';
import { Item } from './Item';
import { Skeleton } from './Skeleton';
import { messages } from './intl';
import { ProgrammeBanner } from './ProgrammeBanner';

export const TodayObservations = () => {
  const { loading, error, data, hasMore, refetch, fetchMore } =
    useTodayPatientObservations();
  const { data: hypetensionHomeBanner } = useHypertensionHomeBannerStatus();

  if (loading && !data) {
    return <Skeleton />;
  }

  if (!error && data?.length === 0 && !hypetensionHomeBanner) {
    return null;
  }

  return (
    <TasksContainer>
      <Body>
        <FormattedMessage {...messages.title} />
      </Body>
      <Spacer size="s4" />
      <Grid data-testid="readings">
        {error && <ErrorNotification retry={refetch} />}
        <ProgrammeBanner data={hypetensionHomeBanner} />
        {!error &&
          data?.map((observation) => (
            <Item
              observation={observation as WardPatientObservation}
              key={observation.id}
            />
          ))}
      </Grid>
      <Spacer size="s6" />
      <Footer>
        {hasMore && (
          <Button
            onClick={() => {
              fetchMore();
            }}
          >
            <Text
              color={colors.primaryIndigo.indigo08}
              fontSize={fontSizes.base}
            >
              <FormattedMessage {...messages.viewMore} />
            </Text>
          </Button>
        )}
      </Footer>
    </TasksContainer>
  );
};
