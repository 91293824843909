import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  Checkbox,
  Col,
  colors,
  Grid,
  Text,
  GRID_BREAKPOINT,
  spacing,
  fontSizes,
  Stack,
  Spacer,
} from '@bt-healthcare/ui-toolkit';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTracking } from '../../hooks/useTracking';

import {
  CustomCol,
  DescriptionText,
  Form,
  GradientBackground,
  HeaderText,
  HeroContainer,
  NoteText,
} from './styles';
import { schema, StepUpInvitationFormValues } from './data';

import { ReactComponent as StepUpInvitationHero } from '../../assets/step-up-invitation.svg';
import { ROUTE } from '../../config/routes';
import {
  CARE_SETTING_NAME,
  STEP_UP_NOT_VERIFIED,
  WARD_PATIENT_ID,
} from '../../App.constants';
import { useCareSettingStepUpAcceptanceMutation } from '../../services/graphql';

export const StepUpInvitation = () => {
  const { trackPage, trackTapped } = useTracking();
  const navigate = useNavigate();
  const stepUpNotVerified = localStorage.getItem(
    STEP_UP_NOT_VERIFIED
  ) as string;
  const careSettingName = localStorage.getItem(CARE_SETTING_NAME) as string;
  const wardPatientId = localStorage.getItem(WARD_PATIENT_ID) as string;
  const pageName = 'Step Up - Accept Terms and Conditions';
  const [careSettingStepUpAcceptance, { data, loading }] =
    useCareSettingStepUpAcceptanceMutation();

  const {
    handleSubmit,
    register,
    formState: { isValid },
    getValues,
  } = useForm<StepUpInvitationFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      agree: false,
    },
  });

  useEffect(() => {
    trackPage(pageName);
  }, []);

  useEffect(() => {
    if (data !== undefined && data?.careSettingStepUpAcceptance) {
      localStorage.removeItem(CARE_SETTING_NAME);
      localStorage.removeItem(WARD_PATIENT_ID);
      navigate(ROUTE.STEP_UP_SUCCESS);
    }
  }, [data]);

  const onSubmit = handleSubmit(async () => {
    trackTapped('Accept', pageName);
    await careSettingStepUpAcceptance({
      variables: {
        input: {
          wardPatientId,
        },
      },
    });
  });

  if (stepUpNotVerified) {
    return <Navigate to={ROUTE.ACTIVATE} />;
  }

  return (
    <Form onSubmit={onSubmit}>
      <Stack space="none" id="">
        <Spacer size="s17" />
        <Grid>
          <Col
            col={{
              [GRID_BREAKPOINT.small]: '1 / 5',
              [GRID_BREAKPOINT.medium]: '1 / 9',
              [GRID_BREAKPOINT.large]: '4 / 10',
            }}
            justifyContent="center"
          >
            <HeroContainer>
              <Stack
                id="step-up-invitation-header"
                space="s4"
                alignItems="center"
              >
                <StepUpInvitationHero />
                <Spacer size="s8" />
                <HeaderText>
                  <FormattedMessage
                    id="step-up-invitation.title"
                    defaultMessage="You've been invited by the {careSettingName} NHS team to join the HealthyYou remote care service."
                    values={{ careSettingName }}
                  />
                </HeaderText>
              </Stack>
            </HeroContainer>
          </Col>
          <Col
            col={{
              [GRID_BREAKPOINT.small]: '1 / 5',
              [GRID_BREAKPOINT.medium]: '1 / 9',
              [GRID_BREAKPOINT.large]: '4 / 10',
            }}
          >
            <Stack id="step-up-invitation-header" space="s5">
              <DescriptionText>
                <FormattedMessage
                  id="step-up-invitation.desc1"
                  defaultMessage="Joining this service allows you to regularly and securely share your health and lifestyle information with your NHS healthcare provider, without needing to go to the GP practice. This gives your clinicians a clearer picture of your health, while providing tools to help support you in a way that fits around you and your life."
                />
              </DescriptionText>
              <DescriptionText>
                <FormattedMessage
                  id="step-up-invitation.desc2"
                  defaultMessage="The data shared with {careSettingName} NHS may include health information you enter into the HealthyYou app, such as your blood pressure, heart rhythm, and heart rate, as well as activity and lifestyle information and other related metrics or personal data, in accordance with your care provider’s privacy policy."
                  values={{ careSettingName }}
                />
              </DescriptionText>
              <NoteText>
                <Text color={colors.grey.grey07} fontWeight={700}>
                  <FormattedMessage
                    id="step-up-invitation.desc3"
                    defaultMessage="Please note:"
                  />
                </Text>{' '}
                <FormattedMessage
                  id="step-up-invitation.desc4"
                  defaultMessage="Our clinicians will not actively monitor this data on an ongoing basis. If you ever experience a medical emergency, please contact emergency services directly."
                />
              </NoteText>
              <NoteText>
                <FormattedMessage
                  id="step-up-invitation.desc5"
                  defaultMessage="By ticking the box below, you agree to share your health and lifestyle information saved on the HealthyYou app with the {careSettingName} NHS remote care team."
                  values={{ careSettingName }}
                />
              </NoteText>
              <NoteText>
                <FormattedMessage
                  id="step-up-invitation.desc6"
                  defaultMessage="If you decide to opt-out later, simply reach out to the {careSettingName} NHS remote care team directly, and they will take care of it for you."
                  values={{ careSettingName }}
                />
              </NoteText>
            </Stack>
          </Col>
        </Grid>
      </Stack>
      <GradientBackground />
      <Grid
        style={{
          bottom: 0,
          insetInline: 0,
          position: 'fixed',
          padding: `${spacing.s6}`,
          gridAutoFlow: 'dense',
          backgroundColor: 'white',
          gap: 24,
        }}
      >
        <Col
          col={{
            [GRID_BREAKPOINT.small]: '1 / 5',
            [GRID_BREAKPOINT.medium]: '1 / 9',
            [GRID_BREAKPOINT.large]: '4 / 8',
          }}
        >
          <Checkbox
            id="step-up-invitation-checkbox"
            {...register('agree')}
            label={
              <Text color={colors.grey.grey09} fontSize={fontSizes.sm}>
                <FormattedMessage
                  id="step-up-invitation.agree"
                  defaultMessage="I agree to share my health and lifestyle data with clinicians at {careSettingName} NHS"
                  values={{ careSettingName }}
                />
              </Text>
            }
            style={{
              alignSelf: 'flex-start',
              top: 0,
              borderColor: getValues('agree')
                ? colors.primaryIndigo.indigo08
                : colors.grey.grey10,
            }}
          />
        </Col>
        <CustomCol
          col={{
            [GRID_BREAKPOINT.small]: '1 / 5',
            [GRID_BREAKPOINT.medium]: '1 / 9',
            [GRID_BREAKPOINT.large]: '8 / 10',
          }}
        >
          <Button
            id="step-up-invitation-accept"
            type="submit"
            disabled={!isValid}
            isLoading={loading}
          >
            <FormattedMessage
              id="step-up-invitation.accept"
              defaultMessage="Accept"
            />
          </Button>
        </CustomCol>
      </Grid>
    </Form>
  );
};
