import { Grid, Spacer } from '@bt-healthcare/ui-toolkit';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { AppointmentButtonsList } from '../../components/AppointmentButtonsList';
import { TodayObservations } from '../../components/TodayObservations';
import { useTracking } from '../../hooks/useTracking';

import { ReactComponent as Backdrop } from '../../assets/home-backdrop.svg';
import { HealthScoreInfoCard } from '../../components/Cards/HealthScoreInfoCard';
import { TakeReadingCard } from '../../components/Cards/TakeReadingCard';
import { UpcomingAppointmentButtonsList } from '../../components/UpcomingAppointmentButtonsList';
import { ROUTE } from '../../config/routes';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { GreetingHeader } from './GreetingHeader';
import { Header } from './Header';
import { HealthScoreBanner } from './HealthScoreBanner';
import {
  Container,
  Content,
  GradientBackground,
  GridItem,
  Top,
  TopBackground,
  TopSection,
} from './styles';

import { HealthyYouPopup } from '../../components/Popups/HealthyYouPopup';
import { AddReadingCard } from '../../components/Cards/AddReadingCard';

export const Home = () => {
  const flags = useFeatureFlags();
  const { trackPageWithAppName } = useTracking();
  const [openHYPopup, setOpenHYPopup] = useState(false);

  useEffect(() => {
    trackPageWithAppName('Home');
  }, []);

  return (
    <>
      {!flags.HEALTH_SCORE && (
        <TopBackground>
          <Backdrop width="100%" height="100%" preserveAspectRatio="none" />
        </TopBackground>
      )}
      <Container>
        {flags.HEALTH_SCORE && (
          <TopSection>
            <GradientBackground />
            <GreetingHeader />
            <HealthScoreBanner setOpenHYPopup={setOpenHYPopup} />
          </TopSection>
        )}
        {!flags.HEALTH_SCORE && (
          <>
            <Header />
            <Spacer size="s10" />
          </>
        )}
        <Content>
          <Top>
            <Grid>
              <GridItem>
                <Link to={ROUTE.CREATE_OBSERVATION}>
                  {flags.HEALTH_SCORE ? (
                    <AddReadingCard />
                  ) : (
                    <TakeReadingCard />
                  )}
                </Link>
              </GridItem>
            </Grid>
            <Spacer size="s7" />
            {flags.HEALTH_SCORE_3994 && (
              <Grid>
                <GridItem>
                  <Link to={ROUTE.HEALTH_SCORE_PERSONAL_INFO}>
                    <HealthScoreInfoCard>Health Score Form</HealthScoreInfoCard>
                  </Link>
                </GridItem>
              </Grid>
            )}
            <TodayObservations />
            {flags.REMOTE_CARE_AND_NAVIGATION && (
              <UpcomingAppointmentButtonsList />
            )}
            <Spacer size="s7" />
            {flags.REMOTE_CARE_AND_NAVIGATION && <AppointmentButtonsList />}
          </Top>
        </Content>
      </Container>
      {openHYPopup && <HealthyYouPopup setOpenHYPopup={setOpenHYPopup} />}
    </>
  );
};
