import {
  Stack,
  Input,
  Spacer,
  colors,
  Button,
  Text,
  Notification,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { PublicContentCardFormWrapper } from '../../components/PublicContentCard/styles';
import { ROUTE } from '../../config/routes';
import { schema } from './data';
import {
  OnboardingEmailAddressFormValues,
  OnboardingEmailAddressProps,
} from './types';
import { useTracking } from '../../hooks/useTracking';

export const OnboardingEmailAddressForm = ({
  createUserRegistrationRecord,
  data,
  isEmailInUse,
  loading,
}: OnboardingEmailAddressProps) => {
  const { trackEvent } = useTracking();
  const intl = useIntl();

  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    getValues,
    formState: { isValid, errors },
  } = useForm<OnboardingEmailAddressFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (data && !isEmailInUse) {
      const { email } = getValues();
      navigate(ROUTE.ONBOARDING_CHECK_EMAIL, {
        state: {
          primaryEmailAddress: email,
        },
      });
    }
  }, [data]);

  const submit = handleSubmit(({ email }) =>
    createUserRegistrationRecord({
      variables: {
        input: {
          primaryEmailAddress: email.toLowerCase(),
        },
      },
      context: {
        isAnonymous: true,
      },
    })
  );

  return (
    <PublicContentCardFormWrapper onSubmit={submit}>
      <Stack space="s2" id="">
        <Input
          id="email"
          label={intl.formatMessage({
            id: 'onboarding-email-address.form.email',
            defaultMessage: 'Email address',
          })}
          type="email"
          errorText={errors.email?.message}
          inputMode="email"
          markAsRequired
          {...register('email')}
        />
        <Input
          id="confirmEmail"
          label={intl.formatMessage({
            id: 'onboarding-email-address.form.confirm-email',
            defaultMessage: 'Confirm email address',
          })}
          errorText={errors.confirmEmail?.message}
          type="email"
          inputMode="email"
          markAsRequired
          {...register('confirmEmail')}
        />
        {isEmailInUse && (
          <>
            <Spacer size="s1" />
            <Notification type="warning">
              <Text color={colors.grey.grey10}>
                <FormattedMessage
                  id="onboarding-email-address.error.email-in-use"
                  defaultMessage="This email address is already in use. Please choose another email address."
                />
              </Text>
            </Notification>
          </>
        )}
      </Stack>
      <Spacer size="s6" />
      <Stack space="s6" id="">
        <Text color={colors.grey.grey08} fontSize={fontSizes.sm}>
          <FormattedMessage
            id="onboarding-email-address.policy.consent"
            defaultMessage="By continuing, you agree to the {a} and {b} for HealthyYou."
            values={{
              a: (
                <Link to={ROUTE.PRIVACY}>
                  <Text color={colors.primaryIndigo.indigo08}>
                    <FormattedMessage
                      id="onboarding-email-address.privacy-policy"
                      defaultMessage="privacy policy"
                    />
                  </Text>
                </Link>
              ),
              b: (
                <Link to={ROUTE.COOKIES}>
                  <Text color={colors.primaryIndigo.indigo08}>
                    <FormattedMessage
                      id="onboarding-email-address.cookie-policy"
                      defaultMessage="cookie policy"
                    />
                  </Text>
                </Link>
              ),
            }}
          />
        </Text>
        <Button
          variant="primary"
          disabled={!isValid}
          isLoading={loading}
          id="continue"
          type="submit"
          role="button"
          tabIndex={0}
          onClick={() => trackEvent('Agree & Continue ', 'click')}
        >
          <FormattedMessage
            id="onboarding-email-address.continue"
            defaultMessage="Agree and continue"
          />
        </Button>
      </Stack>
    </PublicContentCardFormWrapper>
  );
};
