import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useForm, FieldErrors, FormProvider } from 'react-hook-form';

import {
  Button,
  Col,
  colors,
  Header,
  Grid,
  Text,
  Spacer,
  Stack,
  Input,
  fontSizes,
} from '@bt-healthcare/ui-toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatISO } from 'date-fns';

import { useAuthUserProfile } from '../../hooks/useAuthUserProfile';
import { useTracking } from '../../hooks/useTracking';
import {
  ActivateUserMutation,
  RegistrationStatus,
  useActivateUserMutation,
} from '../../services/graphql';

import { validationSchema } from './validation';
import { Form } from './styles';
import type { FormData } from './types';
import { ErrorLabel } from '../../components/ErrorLabel';
import { RHFDateOfBirthInput } from '../../components/RHFDateOfBirthInput';
import { STEP_UP_NOT_VERIFIED } from '../../App.constants';
import { ROUTE } from '../../config/routes';

const validationMessage = (
  errors: FieldErrors<FormData>,
  name: keyof FormData
) => ({
  errorText: errors[name]?.message?.toString(),
});

export const UserActivation = () => {
  const { trackPageWithAppName } = useTracking();
  const intl = useIntl();
  const { data: userData, refetch } = useAuthUserProfile();
  const [activateUserMutation, { error, data }] = useActivateUserMutation();
  const methods = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = methods;
  const stepUpNotVerified = localStorage.getItem(
    STEP_UP_NOT_VERIFIED
  ) as string;
  const navigate = useNavigate();

  const isStepUpFlowActivation = (
    gqlData: ActivateUserMutation | null | undefined
  ) => (
      gqlData !== undefined &&
      gqlData?.activateUser !== null &&
      stepUpNotVerified
    );

  useEffect(() => {
    if (isStepUpFlowActivation(data)) {
      localStorage.removeItem(STEP_UP_NOT_VERIFIED);
      navigate(ROUTE.STEP_UP_INVITATION);
    }
  }, [data]);

  const submit = async (submitData: FormData) => {
    const dob = new Date(
      Number(submitData.yearOfBirth),
      Number(submitData.monthOfBirth) - 1,
      Number(submitData.dayOfBirth)
    );
    await activateUserMutation({
      variables: {
        input: {
          dateOfBirth: formatISO(dob, { representation: 'date' }),
          surname: submitData.surname.trim(),
          userId: userData?.userPatientProfileResults.userId || '',
        },
      },
    });

    await refetch();
  };

  useEffect(() => {
    trackPageWithAppName('Activation');
  }, []);

  if (
    userData?.userPatientProfileResults.user.attributes.registrationStatus ===
      RegistrationStatus.Verified &&
    !stepUpNotVerified
  ) {
    return <Navigate to="/" />;
  }

  return (
    <Grid>
      <FormProvider {...methods}>
        <Col
          colStart={{ xs: 1, md: 1, lg: 5 }}
          colEnd={{ xs: 5, md: 6, lg: 9 }}
        >
          <Stack space="s10" id="user-activation-page__content-wrapper">
            <Spacer size="l4" />
            <Stack space="s6" id="user-activation-page__form-wrapper">
              <Header>
                <FormattedMessage
                  id="activation.welcome.header"
                  defaultMessage="Welcome to HealthyYou"
                />
              </Header>
              <Text color={colors.grey.grey08} fontSize={fontSizes.h4}>
                <FormattedMessage
                  id="activation.welcome.subtitle"
                  defaultMessage="Please confirm your details below to activate your account."
                />
              </Text>
            </Stack>
            <Form onSubmit={handleSubmit(submit)}>
              <Stack space="s6" id="user-activation-page__form-inputs">
                <Input
                  id="surname"
                  label={intl.formatMessage({
                    id: 'activation.surname.field.label',
                    defaultMessage: 'Your surname',
                  })}
                  {...register('surname')}
                  {...validationMessage(errors, 'surname')}
                />
                <RHFDateOfBirthInput />
                <Spacer size="s6" />
                {(error || (data && !data.activateUser)) && (
                  <ErrorLabel>
                    <FormattedMessage
                      id="activation.api.failure"
                      defaultMessage="We couldn't activate your account. Please try again."
                    />
                  </ErrorLabel>
                )}
              </Stack>

              <Button
                id="activate-user"
                type="submit"
                role="button"
                tabIndex={0}
                disabled={!isValid}
              >
                <FormattedMessage
                  id="activation.welcome.button.confirm"
                  defaultMessage="Confirm"
                />
              </Button>
            </Form>
          </Stack>
        </Col>
      </FormProvider>
    </Grid>
  );
};
